
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.layout-header {
  $parent: &;
  background-color: $color-grayscale-05;
  height: $header-height;
  flex-shrink: 0;
  position: relative;
  z-index: 10;

  &__wrapper {
    height: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--with-gaps {
    padding: 0 24px;
    box-shadow: inset 0px -1px 0px #e5e5e5;
  }

  @include for-size(phone-portrait-down) {
    &:not(#{$parent}--with-gaps) #{$parent}__wrapper {
      position: fixed;
      top: 0;
      left: $header-height;
      width: calc(100% - #{$header-height});
      padding: 0 20px;
      background-color: $color-white;
      box-shadow: $base-shadow-m;

      .esmp-sidebar-toggle-button {
        position: fixed;
        top: 0;
        left: 0;
        box-shadow: $base-shadow-m;
      }
    }
  }
}
